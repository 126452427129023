.ant-card-cover img{
    display: block;
    margin: 0 auto;
    width: 150px;
}


/* Table.css */
.dataframe tbody tr th:only-of-type {
    vertical-align: middle;
}

.dataframe tbody tr th {
    vertical-align: top;
}

.dataframe thead th {
    text-align: right;
}

table.dataframe {
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
    table-layout: fixed;
}

.output_markdown table thead, table.dataframe thead {
    border-bottom: 1px solid #dadada;
    font-family: monospace;
    text-align: right;
}

table.dataframe.tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
table.dataframe.tbody.tr.td {
    text-align: right;
}