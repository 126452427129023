
.footer-items{
    position: relative;
    display: inline;
}

#footer-lang{
    float: right;
}

#footer-credits{
    position: relative;
}