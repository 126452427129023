.image {
    display: flex;
    align-items: center;
    margin: 0 0 0 60px;
}

.ant-page-header-rtl .image {
    margin: 0 60px 0 0;
}

.example-link {
    margin-right: 16px;
    line-height: 24px;
    /*color: #41BBBE;*/
}
[data-theme='compact'] .example-link {
    line-height: 20px;
}
.example-link-icon {
    margin-right: 8px;
    /*font-size: 18px;*/
    color:black;
}

[data-theme='compact'] .example-link-icon {
    width: 20px;
    height: 20px;
}

.ant-page-header-rtl .example-link {
    float: right;
    margin-right: 0;
    margin-left: 16px;
}
.ant-page-header-rtl .example-link-icon {
    margin-right: 0;
    margin-left: 8px;
}

@media (max-width: 768px) {
    .image {
        flex: 100%;
        margin: 24px 0 0;
    }
}