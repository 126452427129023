@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55/var(--tw-text-opacity));
    font-family: 'Source Sans Pro';
    font-size: 16px;
}


.text-orange-800 {
    --tw-text-opacity: 1;
    color: rgb(154 52 18/var(--tw-text-opacity));
}

.bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213/var(--tw-bg-opacity));
}

.text-orange-800 {
    --tw-text-opacity: 1;
    color: rgb(154 52 18/var(--tw-text-opacity));
}

.bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22/var(--tw-bg-opacity));
}

.bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213/var(--tw-bg-opacity));
}


.text-violet-800 {
    --tw-text-opacity: 1;
    color: rgb(91 33 182/var(--tw-text-opacity));
}

.bg-violet-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254/var(--tw-bg-opacity));
}


.text-violet-100 {
    --tw-text-opacity: 1;
    color: rgb(237 233 254/var(--tw-text-opacity));
}

.bg-violet-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246/var(--tw-bg-opacity));
}

.text-fuchsia-800 {
    --tw-text-opacity: 1;
    color: rgb(134 25 143/var(--tw-text-opacity));
}

.bg-fuchsia-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255/var(--tw-bg-opacity));
}


.text-fuchsia-100 {
    --tw-text-opacity: 1;
    color: rgb(250 232 255/var(--tw-text-opacity));
}

.bg-fuchsia-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239/var(--tw-bg-opacity));
}



.leading-8 {
    line-height: 2rem;
}

.py-0\.5 {
    padding-bottom: .125rem;
    padding-top: .125rem;
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
}

.rounded {
    border-radius: .25rem;
}


.font-semibold {
    font-weight: 600;
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}

.px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
}



.ml-1 {
    margin-left: .25rem;
}
