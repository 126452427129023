#rrs_solution .steps-content {
    min-height: 200px;
    margin-top: 20px;
    padding-top: 0px;
    text-align: left;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

#rrs_solution.steps-action {
    margin-top: 24px;
}