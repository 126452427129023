.row-dragging {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}
