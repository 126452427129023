.ant-upload.ant-upload-select-picture-card {
    width: 448px;
    height: 448px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.ant-upload-list-picture-card-container {
    display: inline-block;
    width: 448px;
    height: 448px;
    margin: 0 8px 8px 0;
    vertical-align: top;
}