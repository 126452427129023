
body{
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    overflow-x: hidden;
}
input:focus{
    outline-width: 0;
}

.kw42ni932vs-editor_css{
    opacity: 0;
}
.ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: white;
}

